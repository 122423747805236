import React from 'react'
import { NavLink } from 'react-router-dom'
import Pagination from '../../Pagination/Pagination'
import { connect } from 'react-redux'
import { currencyFormatter } from '../../../modules/formatter'

const ProductsList = (props, { user }) => {
    const storeId = props.storeId
    const ProductList = props.ProductList
    const TotalProducts = props.TotalProducts
    const formatter = props.formatter
    const numberFormatter = props.numberFormatter
    const PageSize = props.PageSize
    const ProductInfo = props.ProductInfo
    const WarehouseDetails = props.WarehouseDetails
    const Sortby = props.Sortby
    const SortDirection = props.SortDirection
    const onSortChange = props.onSortChange
    const onPageSizeChange = props.onPageSizeChange
    const onSortDirectionChange = props.onSortDirectionChange
    return (
        <>

            <div className="card mb-0">
                <div className="card-body">
                    <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                        <h4 className="card-title">Product List</h4>
                        <div className="graph-sets">
                            <div className="dropdown mx-1">
                                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Page Size
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className={ PageSize === "10" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "10") } } href="#">10</a></li>
                                    <li><a className={ PageSize === "20" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "20") } } href="#">20</a></li>
                                    <li><a className={ PageSize === "50" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "50") } } href="#">50</a></li>
                                    <li><a className={ PageSize === "100" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onPageSizeChange(e, "100") } } href="#">100</a></li>
                                </ul>
                            </div>
                            <div className="dropdown">
                                <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Sort by
                                </button>
                                <ul className="dropdown-menu">
                                    <li><a className={ Sortby === "sales" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "sales") } } href="#">Sales (Last 30 days)</a></li>
                                    <li><a className={ Sortby === "units" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "units") } } href="#">Units (Last 30 days)</a></li>
                                    <li><a className={ Sortby === "title" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "title") } } href="#">Product Title</a></li>
                                    <li><a className={ Sortby === "selling_price" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "selling_price") } } href="#">Selling Price</a></li>
                                    <li><a className={ Sortby === "cost" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "cost") } } href="#">Cost</a></li>
                                    <li><a className={ Sortby === "stock" ? "dropdown-item active" : "dropdown-item" } onClick={ (e) => { onSortChange(e, "stock") } } href="#">Stock at Warehouse</a></li>
                                </ul>
                            </div>
                            <div className='py-1'>
                                <button
                                    onClick={ (e) => { onSortDirectionChange(e, "up") } }
                                    className={
                                        SortDirection === "up" ?
                                            'btn btn-primary mx-1 my-1'
                                            :
                                            'btn btn-outline-primary mx-1'
                                    }
                                >
                                    <i class="fa-solid fa-arrow-up-wide-short"></i>
                                </button>
                            </div>
                            <div className='py-1'>
                                <button
                                    onClick={ (e) => { onSortDirectionChange(e, "down") } }
                                    className={
                                        SortDirection === "down" ?
                                            'btn btn-primary mx-1'
                                            :
                                            'btn btn-outline-primary mx-1 my-1'
                                    }
                                >
                                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive text-wrap">
                        <table className="table mb-0">
                            <thead>

                                <tr>
                                    <th>
                                        Title
                                    </th>
                                    <th >
                                        View on Takealot
                                    </th>
                                    {/* <th>
                                        BuyBox
                                        <select className="form-select" name='buyboxStatus' value={props.StatusFilters['buyboxStatus']} onChange={(e)=>{props.onStatusFilterChange(e)}} id='buyboxStatus' aria-label="Default select example">
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                            <option value="2">Eligible</option>
                                            <option value="3">Ineligible</option>
                                            <option value="All">All</option>
                                        </select>
                                    </th> */}
                                    {
                                        Sortby === "sales" || Sortby === "units" ?
                                            <>
                                                <td>
                                                    Units
                                                </td>
                                                <td>
                                                    Sales
                                                </td>
                                            </>
                                            : <></>
                                    }
                                    {
                                        storeId == 0 ?
                                            <th >
                                                Store
                                            </th> : <></>
                                    }
                                    <th >
                                        Price
                                    </th>
                                    {/* <th >
                                        U.O.                                                             
                                        
                                    </th>
                                    <th >
                                        CPU                                       
                                        
                                    </th>
                                    <th >
                                        SPU                                           
                                        
                                    </th>
                                    <th >
                                        SpPU  
                                    </th> */}
                                    <th >
                                        Cost
                                    </th>
                                    <th>
                                        Status
                                        {
                                            Sortby !== "sales" && Sortby !== "units" ?
                                                <select className="form-select" name='status' value={ props.StatusFilters['status'] } onChange={ (e) => { props.onStatusFilterChange(e) } } id='statusFilter' aria-label="Default select example">
                                                    <option value="All">All</option>
                                                    <option value="Buyable">Buyable</option>
                                                    <option value="Not Buyable">Not Buyable</option>
                                                    <option value="Disabled by Seller">Disabled By Seller</option>
                                                </select>
                                                : <></>
                                        }
                                    </th>
                                    {/* <th >Level</th> */ }
                                    <th >
                                        Total
                                    </th>
                                    <th >
                                        CPT
                                    </th>
                                    <th >
                                        JHB
                                    </th>
                                    {/* <th >
                                        Sellers
                                    </th>
                                    <th >
                                        Reviews
                                    </th>
                                    <th >
                                        Ratings
                                    </th> */}
                                    {/* <th>LT Stock</th>
                                    <th>LT Days</th>
                                    <th>Past month</th>
                                    <th>Past year</th>
                                    <th>Total</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !ProductList?.loading ?
                                        ProductList.map(
                                            (key) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {/* <img src={key.image_url} alt="Avatar" className="rounded-circle avatar avatar-md pull-up" /> */ }
                                                                <button style={ { border: 'none', backgroundColor: 'transparent' } } onClick={ (e) => { props.LoadProductDetails(key.tsin_id, key?.sku) } } data-bs-toggle="modal" data-bs-target={ "#productDetailsModal-" + storeId }>{ key?.title?.substring(0, 45) }...</button>

                                                                {/* <NavLink to={{pathname:"/product"}} state={{sku : key.sku}}>
                                                        {key.title}
                                                    </NavLink> */}
                                                            </td>
                                                            <td>
                                                                <a href={ key?.offer_url } target='_blank' style={ { color: 'white' } } className={ "btn btn-primary me-1 bg-label-success" }>
                                                                    <span>Takealot Link</span>
                                                                </a>
                                                            </td>
                                                            {
                                                                Sortby === "sales" || Sortby === "units" ?
                                                                    <>
                                                                        <td>
                                                                            { numberFormatter.format(key?.units) }
                                                                        </td>
                                                                        <td>
                                                                            { currencyFormatter('en-ZA', 'ZAR').format(key?.sales) }
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            {/* <td>
                                                        <div className='row'>
                                                            <div className='col-8'>
                                                                <a href='#' onClick={(e)=>{props.LoadBuyBoxDetails(e, key.tsin_id, key.sku)}}  data-bs-toggle="modal" data-bs-target={"#productBuyBoxDetailsModal-"+storeId} style={{color: 'white'}} className={key.buyBox === 1 ? "btn btn-primary me-1 bg-label-success" : "btn btn-primary me-1 bg-label-danger"}>
                                                                    {
                                                                        key.buyBox === 1 ? 
                                                                        <>
                                                                            <span>Active</span>
                                                                        </>: key.buyBox === 0 ?
                                                                        <>  
                                                                            <span>Inactive</span>
                                                                        </> : key.buyBox === 2 ?
                                                                        <>  
                                                                            <span>Eligible</span>
                                                                        </> :  key.buyBox === 3 ?
                                                                        <>  
                                                                            <span>Ineligible</span>
                                                                        </> :
                                                                        <>
                                                                            <span>Not Buyable</span>
                                                                        </>
                                                                    }
                                                                </a>
                                                            </div>
                                                            <div className='col-4'>
                                                                {
                                                                    key.buyBox === 1 || key.buyBox === 0 ?
                                                                    <div className="form-check form-switch form-switch-xl mb-2">
                                                                        <input className="form-check-input form-switch-xl" type="checkbox" onClick={(e)=>{}} id="flexSwitchCheckChecked" checked={key.status === 1 ? true : false}/>
                                                                    </div>
                                                                    : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td> */}
                                                            {
                                                                storeId == 0 ?
                                                                    <td>
                                                                        {
                                                                            key.store_name
                                                                        }
                                                                    </td>
                                                                    : <></>
                                                            }
                                                            <td>
                                                                { formatter.format(key.selling_price) }
                                                            </td>
                                                            {/* <td>
                                                    <a value={numberFormatter.format(key.total_units_ordered)}>{numberFormatter.format(key.total_units_ordered)}</a>
                                                    </td>
                                                    <td>
                                                    <a  onClick={(e)=>{props.LoadProductCostDetails(e,key.tsin_id)}} data-bs-toggle="modal" data-bs-target={"#editProductCostModal-"+storeId} value={numberFormatter.format(key.cost_per_unit)}>{formatter.format(key.cost_per_unit)}</a>
                                                    </td>
                                                    <td>
                                                    <a   onClick={(e)=>{props.LoadProductCostDetails(e,key.tsin_id)}} data-bs-toggle="modal" data-bs-target={"#editProductCostModal-"+storeId} value={numberFormatter.format(key.storage_cost_per_unit)}>{formatter.format(key.storage_cost_per_unit)}</a>
                                                    </td>
                                                    <td>
                                                    <a   onClick={(e)=>{props.LoadProductCostDetails(e,key.tsin_id)}} data-bs-toggle="modal" data-bs-target={"#editProductCostModal-"+storeId} value={numberFormatter.format(key.shipment_cost_per_unit)}>{formatter.format(key.shipment_cost_per_unit)}</a>
                                                    </td> */}
                                                            <td>
                                                                <a >{ formatter.format(key.cost) }</a>
                                                            </td>
                                                            <td>
                                                                {
                                                                    key.status === "Buyable" ?
                                                                        <span className="bg-label-primary ">
                                                                            { key.status }
                                                                        </span> :
                                                                        key.status === "Not Buyable" ?
                                                                            <span className="bg-label-danger ">
                                                                                { key.status }
                                                                            </span>
                                                                            :
                                                                            <span className="bg-label-secondary ">
                                                                                { key.status }
                                                                            </span>
                                                                }
                                                            </td>
                                                            {/* <td>
                                                                { key.stock_at_takealot_total ? Number(key.stock_at_takealot_total) > 0 ?
                                                                    <>
                                                                        <span className="bg-label-primary ">
                                                                            Stock
                                                                        </span>
                                                                    </>

                                                                    :
                                                                    <span className="bg-label-danger ">
                                                                        No Stock
                                                                    </span> : <></>
                                                                }
                                                            </td> */}
                                                            <td>{ numberFormatter.format(key.stock_at_takealot_total) }</td>
                                                            <td>{ numberFormatter.format(key?.stock_at_jhb) }</td>
                                                            <td>{ numberFormatter.format(key?.stock_at_cpt) }</td>
                                                            {/* <td>{ key.sellers ? key.sellers : 0 }</td>
                                                            <td>{ key.reviews }</td>
                                                            <td>
                                                                {
                                                                    key.reviews === 0 ?
                                                                        <i class="fa-regular fa-star"></i>
                                                                        :
                                                                        <i class="fa-solid fa-star"></i>
                                                                }
                                                                { key.ratings }
                                                            </td> */}
                                                            {/* <th>{numberFormatter.format(key.total_leadtime_stock)}</th>
                                                    <th>{key.leadtime_days ? numberFormatter.format(key.leadtime_days) : 0}</th>
                                                    <th>{formatter.format(key.lastMonthSales)}</th>
                                                    <th>{formatter.format(key.lastYearSales)}</th>
                                                    <th>{formatter.format(key.totalSales)}</th> */}
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        :
                                        Array.from({ length: PageSize }).map((_, index) => (
                                            <tr key={ index }>
                                                <td className="productimgname">
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <h5>
                                                        <span className="skeleton-loader"></span>
                                                    </h5>
                                                </td>
                                            </tr>
                                        ))
                                }
                            </tbody>
                        </table>
                        <br />
                        <div className='pagination justify-content-center'>
                            {
                                TotalProducts > 0 ?
                                    <Pagination totalPages={ props.TotalPages } totalRecords={ TotalProducts } pageLimit={ PageSize } sortBy={ Sortby } sortDirection={ SortDirection } paginationFilters={ props.StatusFilters } paginationSearchQuery={ props.SearchQuery } paginationPageSize={ props.PageSize } accountId={ props.user.id } dashboard={ storeId } paginationOrderBy={ props.OrderBy } onPageChanged={ props.loadProductList } />
                                    // <Pagination  totalRecords={TotalProducts} pageLimit={props.PageSize} paginationFilters={props.StatusFilters} paginationSearchQuery={props.SearchQuery} accountId={props.user.id} dashboard={1} paginationOrderBy={props.OrderBy} onPageChanged={props.loadProductList} />
                                    : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps)(ProductsList)
import React from 'react'
import { Helmet } from "react-helmet"
import { connect } from 'react-redux'
const ProductKPIs = (props) => {
	const KPIsData = props.KPIsData
	const currencyFormatter = props.currencyFormatter
	const numberFormatter = props.numberFormatter
	const ProductData = props.ProductsData
	const PageSize = props.pageSize
	const TotalProducts = props.TotalProducts
	const loadDayProductList = props.loadDayProductList
	return (
		<>
			<Helmet>
				<link rel="stylesheet" href="../assets/css/style.css" />
				<script src="../assets/js/script.js" />
				<script src="../assets/plugins/apexchart/chart-data.js" />
			</Helmet>
			<div>
				<div className="row row-cols-lg-4">
					<div className="col-lg col-sm-6 col-12 d-flex">
						<div className="dash-count">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['total_stock']) }</h4>
								<h5>Total Units</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user"></i>
							</div>
						</div>
					</div>
					<div className="col-lg col-sm-6 col-12 d-flex">
						<div className="dash-count das1">
							<div className="dash-counts">
								<h4>{ currencyFormatter.format(KPIsData['total_value'], 'ZAR') }</h4>
								<h5>Total Value</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user"></i>
							</div>
						</div>
					</div>
					<div className="col-lg col-sm-6 col-12 d-flex">
						<div className="dash-count das2">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['totalJHB']) }</h4>
								<h5>Units at JHB</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user-check"></i>
							</div>
						</div>
					</div>
					<div className="col-lg col-sm-6 col-12 d-flex">
						<div className="dash-count das3">
							<div className="dash-counts">
								<h4>{ currencyFormatter.format(KPIsData['totalJHBValue'], "ZAR") }</h4>
								<h5>Value at JHB</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="user-check"></i>
							</div>
						</div>
					</div>
					<div className="col-lg col-sm-6 col-12 d-flex">
						<div className="dash-count">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['totalCPT']) }</h4>
								<h5>Units at CPT</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file-text"></i>
							</div>
						</div>
					</div>
					<div className="col-lg col-sm-6 col-12 d-flex">
						<div className="dash-count das1">
							<div className="dash-counts">
								<h4>{ currencyFormatter.format(KPIsData['totalCPTValue'], "ZAR") }</h4>
								<h5>Value at CPT</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file-text"></i>
							</div>
						</div>
					</div>
					<div className="col-lg col-sm-6 col-12 d-flex">
						<div className="dash-count das2">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['warehouse']) }</h4>
								<h5>Units at Warehouse</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file"></i>
							</div>
						</div>
					</div>
					<div className="col-lg col-sm-6 col-12 d-flex">
						<div className="dash-count das3">
							<div className="dash-counts">
								<h4>{ numberFormatter.format(KPIsData['warehouse_value']) }</h4>
								<h5>Value at Warehouse</h5>
							</div>
							<div className="dash-imgs">
								<i data-feather="file"></i>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Button trigger modal --> */ }

			</div>
		</>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user,
})

export default connect(mapStateToProps)(ProductKPIs)
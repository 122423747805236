import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import BuyBoxData from '../BuyBoxData'
import BuyBoxModal from '../BuyBoxModal'
import BuyBoxKPIs from '../BuyBoxKPIs'
import Moment from 'moment'
import moment from 'moment-timezone'
import SearchComponent from '../../Search'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

const CompanyBuybox = (props) => {
    const user = props.user
    const CompanyProfile = props.CompanyProfile
    const [TotalProducts, setTotalProducts] = useState(0)
    const [TotalPages, setTotalPages] = useState(0)
    const [LoadingVisibility, setLoadingVisibility] = useState("none")
    const [PageNumber, setPageNumber] = useState(1)
    const [dataVisibility, setDataVisibility] = useState("block")
    const [BuyBoxList, setBuyBoxList] = useState([])
    const [activeInactive, setactiveInactive] = useState(true)

    const activeInactiveChange = () => {
        setactiveInactive(!activeInactive)
    }

    const on_Table_value_update = (e, i) => {
        let newData = [...BuyBoxList]
        newData[i][e.target.name] = e.target.value
        setBuyBoxList(newData)
    }
    const on_Table_status_update = (e, i, status) => {
        let newData = [...BuyBoxList]
        if (status === 1) {
            newData[i][e.target.name] = 0
        } else {
            newData[i][e.target.name] = 1
        }
        setBuyBoxList(newData)
    }
    const [BuyBoxPricingLog, setBuyBoxPricingLog] = useState([])
    const [KPIs, setKPIs] = useState({})
    const [MessageTitle, setMessageTitle] = useState("")
    const [Message, setMessage] = useState("")
    const [MessageVisibility, setMessageVisibility] = useState("none")
    const [MessageType, setMessageType] = useState("primary")
    const [LoadingSalesDetailsVisibility, setLoadingSalesDetailsVisibility] = useState("none")
    const [SalesDetailsVisibility, setSalesDetailsVisibility] = useState("block")
    const [SearchQuery, setSearchQuery] = useState("")
    const [OrderBy, setOrderBy] = useState("title")
    const storeId = 0
    const [ProductInfo, setProductInfo] = useState({
        tsin_id: "",
        offer_id: "",
        sku: "",
        barcode: "",
        product_label_number: "",
        selling_price: 0,
        rrp: 0,
        status: "",
        title: "",
        offer_url: "",
        CPT: 0,
        JHB: 0,
        offerPID: "",
        Level: 0,
        TotalStockLevel: 0,
        isAddToCartAvailable: true
    })
    const [OtherOffers, setOtherOffers] = useState([])
    const [HourDetails, setHourDetails] = useState([])
    const [WarehouseDetails, setWarehouseDetails] = useState([])
    const LoadKPIs = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            account: user.id
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/buybox/kpis/`, Body, config)
            setKPIs(response.data['data'])
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
    }
    const [LoadingLogDetailsVisibility, setLoadingLogDetailsVisibility] = useState(true)

    const LoadPricingLogs = async (offer_id, dashboard) => {
        setLoadingLogDetailsVisibility(true)
        setBuyBoxPricingLog([])
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            'offer_id': offer_id,
            "dashboard": dashboard
        })
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/buybox/logs/`, Body, config)
            setBuyBoxPricingLog(response.data['data'])
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }
        setLoadingLogDetailsVisibility(false)
    }
    const [PageSize, setPageSize] = useState(localStorage.getItem('page-size') ? JSON.parse(localStorage.getItem('page-size')) || 10 : 10)
    const [Sortby, setSortby] = useState(localStorage.getItem('buybox-sort-by') ? localStorage.getItem('buybox-sort-by') || 'title' : 'title')
    const [SortDirection, setSortDirection] = useState(localStorage.getItem('sort-direction') ? localStorage.getItem('sort-direction') || 'down' : 'down')
    const LoadProductDetails = async (tsin_id) => {
        setLoadingSalesDetailsVisibility("block")
        setSalesDetailsVisibility("none")
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "tsin_id": tsin_id,
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/productDetails/`, Body, config)
            setProductInfo(response.data['product'])
            setOtherOffers(response.data['product']['otherOffers'])
        } catch (error) {
            // console.log('first', error)
            //   setResponseError(error.response.statusText)
        }

        setLoadingSalesDetailsVisibility("none")
        setSalesDetailsVisibility("block")
    }
    const loadBuyBoxList = async (page_number, orderBy, store, accountId, searchQuery, page_size, loading, sortBy, sortDirection, activeInactive) => {

        if (loading) {
            setLoadingVisibility("block")
            setDataVisibility("none")
        }
        setBuyBoxList({
            loading: true,
        })
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "page_number": page_number,
            "account": accountId,
            "order_by": orderBy,
            "search_query": searchQuery,
            "page_size": page_size,
            "sortBy": sortBy,
            "sortDirection": sortDirection,
            "autoPricing": activeInactive,
            "buybox": true
        })

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/products/list/`, Body, config)
            // console.log(response.data['buyBoxList'])
            setTotalPages(response.data['total_pages'])
            setBuyBoxList(response.data['results'])
            setTotalProducts(response.data['total_records'])
        } catch (error) {
            console.log('first', error)
        }
        if (loading) {
            setLoadingVisibility("none")
            setDataVisibility("block")
        }
    }
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })


    const loadProductHourDetails = async (tsin_id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(
            {
                "tsin_id": tsin_id
            }
        )
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/buyBox/hourDetails/`, Body, config)

            setHourDetails(response.data)
        } catch (error) {
        }
    }
    const [BuyBox, setBuyBox] = useState({
        tsin_id: "",
        bestHour: "",
        minPrice: "",
        maxPrice: "",
        avgPrice: "",
        autoPricing: 1,
        status: 0
    })
    const onBuyBoxChange = e => {
        setBuyBox({ ...BuyBox, [e.target.name]: e.target.value })
    }
    const LoadBuyBoxDetails = (e, tsin_id) => {
        e.preventDefault()
        LoadProductDetails(tsin_id)
        loadProductHourDetails(tsin_id)
        GetBuyBoxDetails(tsin_id)
    }
    const GetBuyBoxDetails = async (tsin_id) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/buybox/detail/${tsin_id}/`, config)
            // console.log(response.data['buybox_details'])
            setBuyBox(response.data['buybox_details'])
        } catch (error) {
            // console.log('first', error)
        }
    }

    const UpdateBuyBoxDetails = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify(
            BuyBox
        )
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v2/buybox/detail/${BuyBox['tsin_id']}/`, Body, config)
            setMessageTitle("Updated")
            setMessage("Buybox for " + ProductInfo['title'] + " is updated.")
            setMessageVisibility("block")
            setMessageType("primary")
        } catch (error) {
            setMessageTitle("Error")
            setMessage(error.response.status + " " + error.response.statusText)
            setMessageVisibility("block")
            setMessageType("danger")
            // console.log('first', error)
        }

        setTimeout(() => {
            setMessageVisibility("none")
        }, 3000)
    }
    const onBuyBoxSubmit = async e => {
        e.preventDefault()
        await UpdateBuyBoxDetails()

        loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, PageSize, true, activeInactive)

        // window.location.reload();
    }



    const onPageSizeChange = (e, value) => {
        setTotalProducts(0)
        localStorage.setItem('page-size', value)
        setPageSize(value)
        loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, value, false, Sortby, SortDirection, activeInactive)
    }


    const onSortChange = (e, value) => {
        setTotalProducts(0)
        localStorage.setItem('buybox-sort-by', value)
        setSortby(value)
        loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, PageSize, false, value, SortDirection, activeInactive)
    }

    const onSortDirectionChange = (e, value) => {
        setTotalProducts(0)
        localStorage.setItem('sort-direction', value)
        setSortDirection(value)
        loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, PageSize, false, Sortby, value, activeInactive)
    }

    const numberFormatter = new Intl.NumberFormat('en-ZA', {
        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })


    const onSearchInputChange = (e) => {
        e.preventDefault()
        setSearchQuery(e.target.value)
    }


    const updateStatus = async (tsin_id, sku, dashboard, product) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            "dashboard": dashboard,
            "tsin_id": tsin_id,
            "sku": sku,
            "product": product,
        })
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/v2/buybox/update/status/`, Body, config)

            LoadKPIs()
            // loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, PageSize, false, Sortby, SortDirection)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: 'Successfully updated.',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        } catch (error) {
            // console.log('first', error)
        }
    }

    const onProductStatusUpdate = (e, tsin_id, status, sku, dashboard, product) => {
        e.preventDefault()
        updateStatus(tsin_id, status, sku, dashboard, product)
    }


    const updateData = async (offer_id, name, value, dashboard) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        }
        const Body = JSON.stringify({
            dashboard: dashboard,
            name: name,
            value: value
        })

        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/products/details/${offer_id}/`, Body, config)
            LoadKPIs()
            // loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, PageSize, false, Sortby, SortDirection)
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Success",
                html: 'Successfully updated.',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, PageSize, false, Sortby, SortDirection, activeInactive)
        } catch (error) {
            // console.log('first', error)
        }
    }

    const onProductBBDataUpdate = (e, offer_id, name, value, dashboard, rrp, key) => {
        e.preventDefault()
        if (key?.minPrice == 0) {
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Error",
                html: 'Minimum price cannot be 0',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            return
        }
        if (key?.maxPrice == 0) {
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Error",
                html: 'Maximum price cannot be 0',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
            return
        }
        if (value === "" || value === 0 || value === undefined) {
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Error",
                html: 'Value cannot be 0',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
        if (name == "maxPrice" && value > rrp) {
            Swal.fire({
                position: "bottom-end",
                type: "success",
                title: "Error",
                html: 'Max Price cannot be greater than RRP',
                showConfirmButton: !1,
                timer: 1500,
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: !1,
            })
        }
        else {
            updateData(offer_id, name, value, dashboard)
        }
    }

    const onOrderByChange = (e) => {
        e.preventDefault()
        setTotalProducts(0)
        setOrderBy(e.target.value)
        loadBuyBoxList(PageNumber, e.target.value, storeId, user.id, SearchQuery, PageSize, false, Sortby, SortDirection, activeInactive)
    }

    const searchForm = (e) => {
        e.preventDefault()
        setTotalProducts(0)
        loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, PageSize, false, Sortby, SortDirection, activeInactive)
    }
    useEffect(() => {
        LoadKPIs()
        loadBuyBoxList(PageNumber, OrderBy, storeId, user.id, SearchQuery, PageSize, true, Sortby, SortDirection, activeInactive)
    }, [activeInactive, PageNumber])


    return (
        <>
            <Helmet>
                <title>{ CompanyProfile['companyName'] } Buybox</title>
                <link rel="stylesheet" href="../assets/css/style.css" />
                <script src="../assets/js/script.js" />
                <script src="../assets/plugins/apexchart/chart-data.js" />
            </Helmet>
            <div className="content">
                <BuyBoxKPIs
                    KPIsData={ KPIs }
                    numberFormatter={ numberFormatter }
                />
                <SearchComponent
                    placeholder={ "Product Name / SKU / Offer ID / TSIN ID" }
                    SearchQuery={ SearchQuery }
                    onSearchInputChange={ onSearchInputChange }
                    searchForm={ searchForm }
                />
                <BuyBoxData
                    BuyBoxList={ BuyBoxList }
                    on_Table_value_update={ on_Table_value_update }
                    on_Table_status_update={ on_Table_status_update }
                    PageSize={ PageSize }
                    onPageSizeChange={ onPageSizeChange }
                    LoadBuyBoxDetails={ LoadBuyBoxDetails }
                    onOrderByChange={ onOrderByChange }
                    formatter={ formatter }
                    TotalProducts={ TotalProducts }
                    SearchQuery={ SearchQuery }
                    LoadQueryData={ loadBuyBoxList }
                    LoadPricingLogs={ LoadPricingLogs }
                    orderBy={ OrderBy }
                    storeId={ storeId }
                    accountId={ user.id }
                    TotalPages={ TotalPages }
                    onProductStatusUpdate={ onProductStatusUpdate }
                    onProductBBDataUpdate={ onProductBBDataUpdate }
                    Sortby={ Sortby }
                    SortDirection={ SortDirection }
                    onSortChange={ onSortChange }
                    onSortDirectionChange={ onSortDirectionChange }
                    currentPage={ PageNumber }
                    setCurrentPage={ setPageNumber }
                    change_page_numer={ setPageNumber }
                    activeInactive={ activeInactive }
                    activeInactiveChange={ activeInactiveChange }
                />
                <div className="modal fade" id={ `productBuyBoxDetailsModal-${storeId}` } tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog  modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div style={ { textAlign: "center" } }>
                                    <h4 className="modal-title" id="productBuyBoxDetailsTitle" style={ { textAlign: "center" } }><span className="text-muted fw-light">Product /</span> BuyBox</h4>
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body" style={ { wordBreak: "break-all" } }>
                                <div style={ { display: LoadingSalesDetailsVisibility } }>

                                </div>
                                <div style={ { display: SalesDetailsVisibility } } className="row">
                                    <div className='container'>
                                        <div className="row">
                                            <div className="col-md-4 py-3 px-3">
                                                <img className="card-img card-img-left" width="100%" src={ ProductInfo['image_url'] } alt="Card image" />
                                            </div>
                                            <div className="col-md-8 py-3 px-3">
                                                <div className="card-body">
                                                    <h5 className="card-title" >{ ProductInfo['title'] }</h5>
                                                    <br />
                                                    <p>Note: *Product is Eligible for BuyBox only if is buyable, have other offers and have sales in past 7 days.</p>
                                                    <div className='row'>
                                                        <div className='col-xl col-lg col-md-12 col-xs-12'>
                                                            <h4 className="col-form-label h4" style={ { textAlign: "center" } } >Hour Stats (in past 7 days)</h4>
                                                            <div className="row mb-3">
                                                                <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Eligible for BuyBox?*</label>
                                                                {
                                                                    ProductInfo['isAddToCartAvailable'] ?
                                                                        <p className="col-6">
                                                                            Yes
                                                                        </p> :
                                                                        <p className="col-6">
                                                                            Not Eligible
                                                                        </p>
                                                                }
                                                            </div>
                                                            {
                                                                true ?
                                                                    <>
                                                                        <div className='col-xl col-lg col-md-12 col-xs-12'>
                                                                            <form onSubmit={ e => onBuyBoxSubmit(e) }>
                                                                                <div className='row'>
                                                                                    {/* <label className="col-form-label h4" style={{textAlign: "center"}} >Currency ZAR</label> */ }
                                                                                    <div className="row mb-3">
                                                                                        <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Minimum Price</label>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="input-group">
                                                                                                <span className="input-group-text">R</span>
                                                                                                <input type="number" required className="form-control" id="basic-default-name" value={ BuyBox['minPrice'] } onChange={ (e) => { onBuyBoxChange(e) } } name="minPrice" placeholder='Minimum Cost Per Unit' />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-3">
                                                                                        <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Normal Selling Price</label>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="input-group">
                                                                                                <span className="input-group-text">R</span>
                                                                                                <input type="number" required className="form-control" id="basic-default-name" value={ BuyBox['avgPrice'] } onChange={ (e) => { onBuyBoxChange(e) } } name="avgPrice" placeholder='Average Cost Per Unit' />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="row mb-3">
                                                        <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Maximum Price</label>
                                                        <div className="col-sm-6">
                                                        <div className="input-group">
                                                        <span className="input-group-text">R</span>
                                                            <input type="number" required className="form-control" id="basic-default-name" value={BuyBox['maxPrice']} onChange={(e)=>{onBuyBoxChange(e)}} name="maxPrice" placeholder='Maximum Per Unit'/>
                                                        </div>
                                                        </div>
                                                    </div> */}
                                                                                    <div className="row mb-3">
                                                                                        <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Buybox Disabled Hour</label>
                                                                                        <div className="col-sm-6">
                                                                                            <select value={ BuyBox['bestHour'] } name="bestHour" onChange={ (e) => { onBuyBoxChange(e) } } className="form-select">
                                                                                                <option value="">Select</option>
                                                                                                <option value="1">01:00 AM</option>
                                                                                                <option value="2">02:00 AM</option>
                                                                                                <option value="3">03:00 AM</option>
                                                                                                <option value="4">04:00 AM</option>
                                                                                                <option value="5">05:00 AM</option>
                                                                                                <option value="6">06:00 AM</option>
                                                                                                <option value="7">07:00 AM</option>
                                                                                                <option value="8">08:00 AM</option>
                                                                                                <option value="9">09:00 AM</option>
                                                                                                <option value="10">10:00 AM</option>
                                                                                                <option value="11">11:00 AM</option>
                                                                                                <option value="12">12:00 PM</option>
                                                                                                <option value="13">01:00 PM</option>
                                                                                                <option value="14">02:00 PM</option>
                                                                                                <option value="15">03:00 PM</option>
                                                                                                <option value="16">04:00 PM</option>
                                                                                                <option value="17">05:00 PM</option>
                                                                                                <option value="18">06:00 PM</option>
                                                                                                <option value="19">07:00 PM</option>
                                                                                                <option value="20">08:00 PM</option>
                                                                                                <option value="21">09:00 PM</option>
                                                                                                <option value="22">10:00 PM</option>
                                                                                                <option value="23">11:00 PM</option>
                                                                                            </select>
                                                                                            <div id="defaultFormControlHelp" className="form-text">
                                                                                                Suggested Hour { HourDetails['bestHour'] } (Sales: { formatter.format(HourDetails['bestHourSales']) } in last 30 days)
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-3">
                                                                                        <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Auto Pricing Running Cycle**</label>
                                                                                        <div className="col-sm-6">
                                                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name='autoPricing' checked={ BuyBox['autoPricing'] === 1 ? true : false } onChange={ (e) => { BuyBox["autoPricing"] == 1 ? setBuyBox({ ...BuyBox, [e.target.name]: 0 }) : setBuyBox({ ...BuyBox, [e.target.name]: 1 }) } } />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-3">
                                                                                        <label className="col-sm-6 col-form-label" htmlFor="basic-default-company">Status</label>
                                                                                        <div className="col-sm-6">
                                                                                            <select value={ BuyBox['status'] } name="status" onChange={ (e) => { onBuyBoxChange(e) } } className="form-select">
                                                                                                <option value="0">Inactive</option>
                                                                                                <option value="1">Active</option>
                                                                                            </select>
                                                                                            <div id="defaultFormControlHelp" className="form-text">
                                                                                                Suggested Hour { HourDetails['bestHour'] }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        true ?
                                                                                            <>
                                                                                                <div className='row'>
                                                                                                    <div className='col-12'>
                                                                                                        <div className="" style={ { textAlign: "center" } }>
                                                                                                            <button className="col-6 btn btn-sm btn-primary form-select " data-bs-dismiss="modal">Update</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            : <></>
                                                                                    }
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </>
                                                                    : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id={ `productBuyBoxPricingLogModal-${storeId}` } tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div style={ { textAlign: "center" } }>
                                    <h4 className="modal-title" id="productBuyBoxDetailsTitle" style={ { textAlign: "center" } }><span className="text-muted fw-light">Product /</span> BuyBox Autopricing Logs </h4>
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >X</button>
                            </div>
                            <div className="modal-body">
                                <div style={ { display: LoadingSalesDetailsVisibility } }>

                                </div>
                                <div style={ { display: SalesDetailsVisibility } }>
                                    <div className='container'>
                                        <div className="row">
                                            {/* <div className="col-md-4 py-3 px-3">
                                            <img className="card-img card-img-left" width="100%" src={ProductInfo['image_url']} alt="Card image" />
                                        </div> */}
                                            <div className="col-md-12 py-3 px-3">
                                                <h5 className="card-title" >{ ProductInfo['title'] }</h5>
                                                <div className='table-responsive'>
                                                    <table className='table'>
                                                        <thead>
                                                            <th className='col-4'>
                                                                Log Type
                                                            </th>
                                                            <th className='col-4'>
                                                                Price Updated
                                                            </th>
                                                            <th className='col-4'>
                                                                Log Name
                                                            </th>
                                                            <th className='col-4'>
                                                                Date
                                                            </th>
                                                        </thead>
                                                        <tbody className="table-border-bottom-0 text-wrap">
                                                            {
                                                                !LoadingLogDetailsVisibility ?
                                                                    BuyBoxPricingLog.map(
                                                                        (key) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td className='col-2'>
                                                                                            {
                                                                                                key?.log_type == 0 ? "Not Eligible" :
                                                                                                    key?.log_type == 1 ? "Prices are not active" :
                                                                                                        key?.log_type == 2 ? "Takealot API Error" :
                                                                                                            key?.log_type == 3 ? "No Seller Info" :
                                                                                                                key?.log_type == 4 ? "Max Price" :
                                                                                                                    key?.log_type == 5 ? "Product Winning" :
                                                                                                                        key?.log_type == 6 ? "Stock update" :
                                                                                                                            key?.log_type == 7 ? "Skipped" :
                                                                                                                                key?.log_type == 8 ? "Best Hour" :
                                                                                                                                    key?.log_type == 9 ? "Price Update" :
                                                                                                                                        "Winning"
                                                                                            }
                                                                                        </td>
                                                                                        <td className='col-2'>
                                                                                            {
                                                                                                key?.log_type == 0 ? "Not Updated" :
                                                                                                    key?.log_type == 1 ? "Not Updated" :
                                                                                                        key?.log_type == 2 ? "Not Updated" :
                                                                                                            key?.log_type == 3 ? "Not Updated" :
                                                                                                                key?.log_type == 4 ? formatter.format(key.price) :
                                                                                                                    key?.log_type == 5 ? "Not Updated" :
                                                                                                                        key?.log_type == 6 ? "Not Updated" :
                                                                                                                            key?.log_type == 7 ? "Not Updated" :
                                                                                                                                key?.log_type == 8 ? formatter.format(key.price) :
                                                                                                                                    key?.log_type == 9 ? formatter.format(key.price) :
                                                                                                                                        "Winning"
                                                                                            }
                                                                                        </td>
                                                                                        <td className='col-8' style={ { whiteSpace: 'pre-wrap', wordWrap: 'break-word' } }>
                                                                                            { key.logName }
                                                                                        </td>
                                                                                        <td className='col-2'>
                                                                                            { moment.utc(key.created_at).tz('Africa/Johannesburg').format('hh:mm A D MMM YY') }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        }
                                                                    ) :
                                                                    Array.from({ length: 10 }).map((_, index) => (
                                                                        <tr key={ index }>
                                                                            <td>
                                                                                <h5>
                                                                                    <span className="skeleton-loader"></span>
                                                                                </h5>
                                                                            </td>
                                                                            <td>
                                                                                <h5>
                                                                                    <span className="skeleton-loader"></span>
                                                                                </h5>
                                                                            </td>
                                                                            <td>
                                                                                <h5>
                                                                                    <span className="skeleton-loader"></span>
                                                                                </h5>
                                                                            </td>
                                                                            <td>
                                                                                <h5>
                                                                                    <span className="skeleton-loader"></span>
                                                                                </h5>
                                                                            </td>

                                                                        </tr>
                                                                    ))
                                                            }
                                                            <td>

                                                            </td>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
})

export default connect(mapStateToProps, {})(CompanyBuybox)